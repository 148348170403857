.date-dark-mode {
  .react-datepicker {
    background: #090e24 !important;
    color: #ffffff !important;
    left: 20px !important;
    padding: 10px !important;
    //   border: 2px solid #111833f9 !important;
    border: none !important;
    box-shadow: -1px -1px #111833f9, 1px 1px #111833f9;
  }
  .react-datepicker__month-container {
    background: #090e24f9 !important;
    color: #ffffff !important;
  }

  .react-datepicker__day {
    padding: 2px;
    color: #ffffff !important;
    &:hover {
      background: rgb(133, 164, 225) !important;
      color: #000000 !important;
    }
  }

  .react-datepicker__header {
    //   border-bottom: 1px solid gray !important;
    border-bottom: none !important;
    padding: 0 !important;
  }
  .react-datepicker__day-names {
    background: #090e24f9 !important;
  }

  .react-datepicker__day-name {
    //   color: #ffffff !important;
    color: hsl(var(--muted-foreground)) !important;
  }

  .react-datepicker__current-month {
    background: #090e24f9 !important;
    color: #ffffff !important;
    padding: 3px 0 !important;
    font-weight: 400 !important;
  }

  .react-datepicker__navigation {
    // border: 1px solid #111833f9 !important;
    box-shadow: -1px -1px #111833f9, 1px 1px #111833f9;
    border-radius: 5px !important;
    margin: 5px 5px;
  }

  .react-datepicker__navigation-icon--previous {
    top: 1px !important;
    left: -1px !important;
  }

  .react-datepicker__navigation--previous {
    left: 0 !important;
  }

  .react-datepicker__navigation-icon--next {
    top: 1px !important;
    right: -2px !important;
    left: 0 !important;
  }

  .react-datepicker__navigation--next {
    right: 0 !important;
  }

  .react-datepicker__header__dropdown {
    background: #090e24f9 !important;
    color: #ffffff !important;
    padding: 8px 0 !important;
    //   border: 0.5px solid #ffffff;
  }

  .react-datepicker__day--keyboard-selected {
    background: none !important;
  }

  // .react-datepicker__month-dropdown-container {
  //   //   border: 0.5px solid #ffffff;
  //   //   padding: 0 5px !important;
  // }

  .react-datepicker__header {
    background: #03011fb4 !important;
    color: #ffffff !important;
  }

  .react-datepicker__month-option {
    color: #000000;
  }

  .react-datepicker__year-option {
    color: #000000;
  }

  .react-datepicker__input-container {
    background: #27272a !important;
    border-radius: 5px !important;
    input {
      background: #27272a !important;
      padding: 8px 10px 5px 35px !important;
      border-radius: 8px !important;
    }
    .react-datepicker__calendar-icon {
      padding: 10px !important;
    }
  }
}

.react-datepicker {
  // background: #090e24 !important;
  // color: #ffffff !important;
  left: 20px !important;
  padding: 10px !important;
  //   border: 2px solid #111833f9 !important;
  border: none !important;
  box-shadow: -1px -1px #111833f9, 1px 1px #111833f9;
}
.react-datepicker__month-container {
  // background: #090e24f9 !important;
  // color: #ffffff !important;
}

.react-datepicker__day {
  padding: 2px;
  // color: #ffffff !important;
  &:hover {
    // background: rgb(133, 164, 225) !important;
    // color: #000000 !important;
  }
}

.react-datepicker__header {
  //   border-bottom: 1px solid gray !important;
  border-bottom: none !important;
  padding: 0 !important;
}
.react-datepicker__day-names {
  // background: #090e24f9 !important;
}

.react-datepicker__day-name {
  //   color: #ffffff !important;
  color: hsl(var(--muted-foreground)) !important;
}

.react-datepicker__current-month {
  // background: #090e24f9 !important;
  // color: #ffffff !important;
  padding: 3px 0 !important;
  font-weight: 400 !important;
}

.react-datepicker__navigation {
  // ***
  // border: 1px solid #111833f9 !important;
  // ***
  box-shadow: -1px -1px #111833f9, 1px 1px #111833f9;
  border-radius: 5px !important;
  margin: 3px 10px;
}

.react-datepicker__navigation-icon--previous {
  top: 1px !important;
  left: -1px !important;
}

.react-datepicker__navigation--previous {
  left: 0 !important;
}

.react-datepicker__navigation-icon--next {
  top: 1px !important;
  right: -2px !important;
  left: 0 !important;
}

.react-datepicker__navigation--next {
  right: 0 !important;
}

.react-datepicker__header__dropdown {
  // background: #090e24f9 !important;
  // color: #ffffff !important;
  padding: 8px 0 !important;
  //   border: 0.5px solid #ffffff;
}

.react-datepicker__day--keyboard-selected {
  background: none !important;
}

// .react-datepicker__month-dropdown-container {
//   //   border: 0.5px solid #ffffff;
//   //   padding: 0 5px !important;
// }

.react-datepicker__header {
  background: #ffffff !important;
  // color: #ffffff !important;
}

// .react-datepicker__month-option {
//   color: #000000;
// }

// .react-datepicker__year-option {
//   color: #000000;
// }

.react-datepicker__input-container {
  // background: #27272a !important;
  border-radius: 5px !important;
  input {
    border: 1px solid #8c8a8a55;
    // background: #27272a !important;
    padding: 8px 10px 5px 35px !important;
    border-radius: 8px !important;
  }
  .react-datepicker__calendar-icon {
    padding: 10px !important;
  }
}

.react-datepicker__year-wrapper {
  padding-top: 8px;
  justify-content: center !important;
}
