.body-bg {
  scroll-behavior: smooth;
  width: 100%;
  word-wrap: break-word;
  word-wrap: break-word;
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.inner-body {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

// .css-13cymwt-control {
//   background: #000000 !important;
//   border: none !important;
// }

// .css-1fdsijx-ValueContainer {
//   background: #000000 !important;
// }

// .css-1hb7zxy-IndicatorsContainer {
//   background: #000000 !important;
// }

// .css-1nmdiq5-menu {
//   background: #000000 !important;
//   // border: 1px solid red;
// }

// .css-t3ipsp-control {
//   background: #000000 !important;
// }

// .select-class {
//   margin-top: 8px !important;
// }

@import "./home.scss", "./analytics.scss", "./glass.scss", "./calender.scss",
  "./investCards.scss", "./forms.scss";
