.invest-card-wrap {
  position: relative;
  display: grid;
  margin: 0 auto;
  width: 340px;
  border-radius: 16px;
  height: max-content;
  // border: 1px solid #000000;
  //   background: linear-gradient(
  //     209.21deg,
  //     rgb(14, 14, 14) 13.57%,
  //     rgb(17, 18, 32) 98.38%
  //   );
  // box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0.5px inset !important;
  box-shadow: rgba(83, 83, 83, 0.414) 2px 2px 10px;
  //   color: #ffffffb3;
  &:hover {
    scale: 1;
  }
  transition: scale 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.user-investment-card-wrap {
  position: relative;
  display: grid;
  margin: 0 auto;
  width: 360px;
  border-radius: 8px;
  // background: linear-gradient(
  //   209.21deg,
  //   rgb(14, 14, 14) 13.57%,
  //   rgb(17, 18, 32) 98.38%
  // );
  // box-shadow: rgba(83, 83, 83, 0.414) 2px 2px 10px;
  &:hover {
    scale: 1.02;
  }
  transition: scale 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.card-shadows {
  border-radius: 8px;
  box-shadow: rgba(83, 83, 83, 0.414) 2px 2px 10px;
}

.tennis-bg-img {
  background-image: url("https://utfs.io/f/R90fwjl7IKtHdQz5Nqx8hJPAt0oSmD7WRvsTN5x4Mj1GpEbQ");
  background-size: cover;
  // background-repeat: no-repeat;
}

.swim-bg-img {
  background-image: url("https://utfs.io/f/R90fwjl7IKtHdQz5Nqx8hJPAt0oSmD7WRvsTN5x4Mj1GpEbQ");
  background-size: cover;
}
.pool-bg-img {
  background-image: url("https://utfs.io/f/R90fwjl7IKtHTUt8SgdLeyJv0O983Xbhdic2oWESPYnuQlNU");
  background-size: cover;
}
.batminton-bg-img {
  background-image: url("https://utfs.io/f/R90fwjl7IKtHYkAP8bZQepJSU3lBu0tFofLX7xVCdO1Ik8w5");
  background-size: cover;
}
.horse-bg-img {
  background-image: url("https://utfs.io/f/R90fwjl7IKtHGOxmZNKF0E7WqxaXc9Iik3swD5VRfzuMrhTQ");
  background-size: cover;
}
