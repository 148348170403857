.ic-box-bg {
  background: rgba(41, 41, 72, 0.471);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 40px 80px,
    rgba(255, 255, 255, 0.15) 0px 0px 0px 0.5px inset;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0px 5px 5px;
  z-index: 1;
  // &:hover {
  backdrop-filter: blur(10px);
  transition: backdrop-filter 4s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  // }

  // transform: skewY(8deg);
}

.ic-box-wrap {
  // cursor: pointer;
  &:hover {
    .float-shadow {
      // transform: skewY(10deg);
      transition: transform 4s cubic-bezier(0.075, 0.82, 0.165, 0.1) 0s;
    }

    scale: 1.05;

    transition: scale 4s cubic-bezier(0.075, 0.82, 0.165, 0.1) 0s;
  }
}

.ic-box-shadow {
  position: absolute;
  width: 2px;
  // height: 100%;
  height: calc(100% - 5px);
  background: linear-gradient(#ff5160 0%, #ed8f1b 100%);
  border-radius: 5px 5px;
  // transform: skewY(2deg);
  // transform-origin: left top;
  filter: blur(2px);
  bottom: 0px;
  right: 0px;
}

.ic-box-shadow-hoz {
  position: absolute;
  width: calc(100% - 10px);
  width: 100%;
  height: 2px;

  background: linear-gradient(#ff5160 0%, #ed8f1b 100%);
  border-radius: 5px 5px;
  // transform: skewY(1deg);
  // transform-origin: left top;
  filter: blur(2px);
  bottom: 0px;
  right: 0px;
}

.ic-box-shadow-tl {
  position: absolute;
  width: 3px;
  // height: 100%;
  height: calc(100% - 5px);
  background: linear-gradient(#ffaa40 0%, #ff5160 100%);
  border-radius: 5px 5px;
  // transform: skewY(2deg);
  // transform-origin: left top;
  filter: blur(2px);
  top: 0px;
  left: 0px;
}

.ic-box-shadow-hoz-tl {
  position: absolute;
  width: calc(100% - 10px);
  width: 100%;
  height: 3px;

  background: linear-gradient(#ffaa40 0%, #ff5160 100%);
  border-radius: 5px 5px;
  // transform: skewY(1deg);
  // transform-origin: left top;
  filter: blur(2px);
  top: 0px;
  left: 0px;
}

.invest-card-wrap-2 {
  position: relative;
  display: grid;
  width: 280px;
  height: 360px;
  border-radius: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  //   box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0.5px inset !important;
  padding: 8px;
  color: #ffffffb3;
  &:hover {
    scale: 1.05;
  }
  transition: scale 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.f-s-theme {
  background: linear-gradient(#ffaa40 0%, #ff5160 100%) !important;
}

.d-s-theme {
  background: linear-gradient(#799eed 0%, #58f838 100%) !important;
}

//lightMode

.d-box-bg {
  background: rgba(181, 181, 183, 0.471);
  box-shadow: rgba(77, 76, 76, 0.25) 0px 40px 80px,
    rgba(255, 255, 255, 0.15) 0px 0px 0px 0.5px inset;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0px 5px 5px;
  z-index: 1;
  // &:hover {
  backdrop-filter: blur(10px);
  transition: backdrop-filter 4s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  // }

  // transform: skewY(8deg);
}

.scale-smooth-wrap {
  &:hover {
    scale: 1.1;
    transition: scale 4s cubic-bezier(0.075, 0.82, 0.165, 0.1) 0s;
  }
}

.d-box-wrap {
  // cursor: pointer;
  &:hover {
    .float-shadow {
      // transform: skewY(10deg);
      transition: transform 4s cubic-bezier(0.075, 0.82, 0.165, 0.1) 0s;
    }

    scale: 1.05;

    transition: scale 4s cubic-bezier(0.075, 0.82, 0.165, 0.1) 0s;
  }
}

.d-box-shadow {
  position: absolute;
  width: 5px;
  // height: 100%;
  height: calc(100% - 5px);
  background: linear-gradient(rgb(192, 52, 149) 0%, rgb(76, 36, 214) 100%);
  border-radius: 5px 5px;
  // transform: skewY(2deg);
  // transform-origin: left top;
  filter: blur(2px);
  bottom: 0px;
  right: 0px;
}

.d-box-shadow-tl {
  position: absolute;
  width: 5px;
  // height: 100%;
  height: calc(100% - 5px);
  background: linear-gradient(rgb(192, 52, 149) 0%, rgb(76, 36, 214) 100%);
  border-radius: 5px 5px;
  // transform: skewY(2deg);
  // transform-origin: left top;
  filter: blur(2px);
  top: 0px;
  left: 0px;
}

.d-box-shadow-hoz {
  position: absolute;
  width: calc(100% - 10px);
  width: 98%;
  height: 3px;

  background: linear-gradient(rgb(192, 52, 149) 0%, rgb(76, 36, 214) 100%);
  border-radius: 5px 5px;
  // transform: skewY(1deg);
  // transform-origin: left top;
  filter: blur(2px);
  bottom: 0px;
  right: 0px;
}

.d-box-shadow-hoz-tl {
  position: absolute;
  width: calc(100% - 10px);
  // width: 98%;
  height: 5px;

  background: linear-gradient(rgb(192, 52, 149) 0%, rgb(76, 36, 214) 100%);
  border-radius: 5px 5px;
  // transform: skewY(1deg);
  // transform-origin: left top;
  filter: blur(2px);
  top: 0px;
  left: 0px;
}
